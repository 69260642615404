<template>
  <v-card elevation="0">
    <v-card-text v-if="!disabled">
      <v-file-input
        dense
        outlined
        multiple
        :label="$t('file.action.upload')"
        :loading="uploadLoading"
        :messages="messages"
        @change="onFileChange"
        :disabled="uploadLoading"
        v-model="file"
      >
        <template #append-outer>
          <v-tooltip top>
            <template #activator="{on}">
              <v-btn
                v-on="on"
                :disabled="uploadLoading"
                @click="openFileManager"
                icon
                style="margin-top: -6px"
              >
                <v-icon>fa fa-folder-open</v-icon>
              </v-btn>
            </template>
            <span>{{'file_manager'| t}}</span>
          </v-tooltip>
        </template>
      </v-file-input>
    </v-card-text>

    <v-card-text v-if="!hideFileList">
      <div class="file-uploader-files elevation-4">
        <v-list dense v-if="fileData">
          <file-item
            @removeFile="removeFile"
            v-for="(fileItem, index) in fileData"
            :key="index"
            :index="index"
            :disabled="disabled"
            :file="fileItem"
          ></file-item>
        </v-list>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="babel" type="text/babel">
import _take from 'lodash/take'
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
    },
    fileValidate: {
      type: Function,
      default: () => true,
    },
    value: {
      type: Array,
      default: () => null,
    },
    maxQuantity: {
      type: [Number, String],
      default: 0,
    },
    hideFileList: {
      type: Boolean,
      default: false,
    },
    customMaxFileSizeMb: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    file: null,
    uploadLoading: false,
    fileData: null,
  }),
  methods: {
    openFileManager() {
      this.$fileSelector({
        max: 10,
        multiple: true,
        applyCallback: (data) => {
          if(Array.isArray(data)) {
            for(const item of data) {
              this.addFile(item.file)
            }
          }

          else {
            this.addFile(data.file)
          }
        },
      })
    },
    removeFile(index) {
      this.$delete(this.fileData, index)

      if(window.eagleLodash.isEmpty(this.fileData)) {
        this.fileData = null
      }
    },
    checkFileSizeValid(file) {
      if(!file) return true
      return file.size <= this.maxFileSizeMb*1024*1024
    },
    async onFileChange() {
      if(window.eagleLodash.isEmpty(this.file)) {
        return
      }
      await this.$nextTick()
      await this.handleFileList(this.file)
    },
    async handleFileList(files) {
      if(this.uploadLoading == true) return
      this.uploadLoading = true
      for(const index in files) {
        const file = files[index]
        await this.handleFile(file)
      }
      this.uploadLoading = false
      this.$snotify.info(
        this.$t('file.upload.successfully.content', { quantity: this.fileQuantity }),
        this.$t('file.upload.successfully')
      )
      await this.$nextTick()
      this.file = null
    },
    async handleFile(file) {
      if(typeof this.fileValidate != 'function') return
      const isSizeValid = this.checkFileSizeValid(file)
      if(!isSizeValid) {
        this.$snotify.warning(`${file.name}`, this.$t('file.warning.max_size_limit'))
        return
      }

      const isValid = this.fileValidate(file)
      if(isValid != true) {
        return
      }
      if(file) {
        await this.uploadFile(file)
      }
    },
    async uploadFile(file) {
      const formData = this.getFormData(file)
      if(!formData) return

      try {
        const uploadResult = await this.uploadFileRequest(this.type, formData, this.onUploadProcess)
        this.$snotify.success(file.name, this.$t('file.upload.successfully'))
        this.addFile(uploadResult)
      } catch (error) {
        console.error(error)
        this.$snotify.error(`${file.name}`, this.$t('file.upload.failure'))
      }
    },
    addFile(newFile) {
      let files = window.eagleLodash.cloneDeep(this.fileData)
      if(!Array.isArray(files)) files = []
      files.unshift(newFile)

      if(this.maxQuantityInteger) {
        files = _take(files, this.maxQuantityInteger)
      }

      this.$emit('addFile', newFile)
      this.fileData = files
    },
    onUploadProcess() {

    },
    getFormData(file) {
      if(!file) return null
      const formData = new FormData()
      formData.append('file', file)
      return formData
    },
  },
  computed: {
    hasFileList() {
      if(!Array.isArray(this.fileData)) return false
      return this.fileData.length > 0
    },
    messages() {
      let messages = this.$t('file.help.max_size_limit', { max: this.maxFileSizeMb })
      if(this.maxQuantityInteger) {
        messages += `, ${this.$t('file.help.max_quantity_limit', { quantity: this.maxQuantityInteger })}`
      }
      return messages
    },
    fileQuantity() {
      if(!Array.isArray(this.file)) return 0
      return this.file.length
    },
    maxQuantityInteger() {
      const maxQuantity = parseInt(this.maxQuantity)
      if(isNaN(maxQuantity)) return 0
      return maxQuantity
    },
    maxFileSizeMb() {
      if(this.customMaxFileSizeMb) return this.customMaxFileSizeMb
      return this.$store.getters['base/eagleEnv'].maxFileSizeMb
    },
    uploadFileRequest() {
      return this.$api.collection.fileApi.upload
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.fileData = window.eagleLodash.cloneDeep(this.value)
      },
    },
    fileData: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.fileData, this.value)) return
        this.$emit('input', window.eagleLodash.cloneDeep(this.fileData))
      },
    },
  },
  components: {
    fileItem: () => import('./_fileItem.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
.file-uploader-files
  max-height: 300px
  overflow: auto
</style>
